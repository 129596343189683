/* eslint-disable @typescript-eslint/no-explicit-any */
import { Global } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import GlobalStyles from '@mui/material/GlobalStyles'
import { ThemeProvider } from '@mui/material/styles'
import React from 'react'
import { spotMeTheme } from 'src/constants/createTheme'
import { globalStyles } from 'src/constants/theme'

const inputGlobalStyles = <GlobalStyles styles={globalStyles} />

type IProps = {
  children: JSX.Element | JSX.Element[]
}

function AllThemeProviders({ children }: IProps) {
  return (
    <ThemeProvider theme={spotMeTheme}>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            maxHeight: `calc(100vh - 90px)`,
            overflow: 'visible'
          }
        }}
      />
      {inputGlobalStyles}
      {children}
    </ThemeProvider>
  )
}

export default React.memo(AllThemeProviders)
