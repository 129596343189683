import { createTheme } from '@mui/material/styles'
import { rubik } from './fonts'
import { Colors } from './theme'

export const spotMeTheme = createTheme({
  spacing: 10,
  breakpoints: {
    values: {
      xs: 0,
      sm: 428,
      md: 900,
      lg: 1024,
      xl: 1280
    }
  },
  palette: {
    primary: {
      main: Colors.primary,
      dark: Colors.primary,
      contrastText: Colors.white
    },
    secondary: {
      main: Colors.primary,
      contrastText: Colors.white
    },
    info: {
      main: Colors.text,
      contrastText: Colors.white
    },
    lightest: {
      main: Colors.white
    },
    darkest: {
      main: Colors.black
    }
  },
  typography: {
    fontFamily: rubik.style.fontFamily,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600
  }
})

// This is the most important text in a page, usually the title
spotMeTheme.typography.h1 = {
  fontSize: '24px',
  fontWeight: 600,
  color: Colors.blue500,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '45px'
  }
}

// This is for the title of a section
spotMeTheme.typography.h2 = {
  fontSize: '18px',
  lineHeight: '28px',
  fontWeight: 500,
  color: Colors.blue500,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '30px'
  }
}

// This is for titles inside small containers like cards
spotMeTheme.typography.h3 = {
  fontSize: '18px',
  fontWeight: 500,
  color: Colors.black400,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '22px'
  }
}

// This is only ment for menus
spotMeTheme.typography.h4 = {
  fontSize: '16px',
  fontWeight: 500,
  color: Colors.black400,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '18px'
  }
}

// This if for small labels
spotMeTheme.typography.h5 = {
  fontSize: '12px',
  fontWeight: 'normal',
  color: Colors.black400,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '14px'
  }
}

// This is a wildcard in case is needed
spotMeTheme.typography.h6 = {
  fontSize: '12px',
  fontWeight: 'normal',
  color: Colors.black400,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '14px'
  }
}

// This is usually alongside the title
spotMeTheme.typography.subtitle1 = {
  fontSize: '12px',
  fontWeight: 500,
  color: Colors.titleGray,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '20px'
  }
}

// This is for subtitles inside small containers like cards
spotMeTheme.typography.subtitle2 = {
  fontSize: '12px',
  fontWeight: 600,
  color: Colors.titleGray,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '12px'
  }
}

// This is for paragraphs inside small containers like cards
spotMeTheme.typography.body2 = {
  fontSize: '12px',
  fontWeight: 'normal',
  color: Colors.black400,
  [spotMeTheme.breakpoints.up('md')]: {
    fontSize: '12px'
  }
}
